import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

/**
 * Obtiene un horario flexible a partir del contrato de un trabajador
 * @param {object} data 
 * @param {string} data.contract_id
 * @returns {AxiosResponse}
 */
export default async function getFlexibleScheduleByContractId(data) {
    const response = await apiService.get('/flexibleSchedules/by-contract',data);
    return response;
}
